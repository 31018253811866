import { Bem } from '@scm/ui-core'

export const videoPlayer = Bem('VideoPlayer')

export const THUMBNAIL_DEFAULT_WIDTH = 1280

export const THUMBNAIL_DEFAULT_QUALITY = 95

export const DEFAULT_PLAYER_WIDTH = '100%'

export const DEFAULT_PLAYER_HEIGHT = '100%'
