import { useState } from 'react'

import { videoPlayer } from '../../constants'
import { VideoPlayerWithThumbnailProps } from '../../type'
import { VideoPlayerThumbnail } from '../thumbnail'
import { SourceBasedPlayer } from './sourceBasedPlayer'

export const VideoPlaylistPlayer = ({
  video,
  thumbnailImage,
  isVideoStarted,
  setIsVideoStarted,
  playNext,
}: VideoPlayerWithThumbnailProps) => {
  const [fadeOut, setFadeOut] = useState(false)

  const startVideo = () => {
    setFadeOut(true)
    setTimeout(() => {
      if (setIsVideoStarted) {
        setIsVideoStarted(true)
      }
    }, 1000)
  }

  return (
    <div className={videoPlayer('Wrapper')} data-testid="playlist-player">
      {!isVideoStarted && (
        <VideoPlayerThumbnail
          thumbnailImage={thumbnailImage}
          onClick={startVideo}
          className={videoPlayer('PlaylistThumbnailWrapper', {
            fadeOut,
          })}
        />
      )}
      {isVideoStarted && (
        <SourceBasedPlayer
          video={video}
          setIsVideoStarted={setIsVideoStarted}
          playNext={playNext}
        />
      )}
    </div>
  )
}
