import { Media } from '@scm/ui-component-media'

import { VideoPlayerWithThumbnail, VideoPlaylistPlayer } from './components'
import { videoPlayer } from './constants'
import { VideoPlayerProps } from './type'
import { getVideoProps } from './utils'
import { selectors } from '@scm/redux-store'

export const VideoPlayer = ({
  video,
  thumbnailImage,
  withThumbnail,
  className,
  isPlaylist,
  isVideoStarted,
  setIsVideoStarted,
  playNext,
}: VideoPlayerProps) => {
  const { media } = selectors.useConfig()
  const videoProps = getVideoProps(video, media)

  if (!videoProps) return null

  const isPlayerWithThumbnail = !isPlaylist && withThumbnail
  const isDefaultMediaPlayer = !isPlaylist && !withThumbnail

  return (
    <div className={videoPlayer({}, [className])} data-component="video-player">
      {isPlaylist && (
        <VideoPlaylistPlayer
          video={videoProps}
          thumbnailImage={thumbnailImage}
          isVideoStarted={isVideoStarted}
          setIsVideoStarted={setIsVideoStarted}
          playNext={playNext}
        />
      )}
      {isPlayerWithThumbnail && (
        <VideoPlayerWithThumbnail
          video={videoProps}
          thumbnailImage={thumbnailImage}
        />
      )}
      {isDefaultMediaPlayer && <Media {...videoProps} />}
    </div>
  )
}
