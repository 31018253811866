import React, { Dispatch, SetStateAction, createContext, useState } from 'react'

export interface VideoPlaylistContextProps {
  currentVideoUid: string
  isVideoStarted: boolean
  setIsVideoStarted: Dispatch<SetStateAction<boolean>>
  playNext: () => void
}

export interface VideoPlaylistProviderProps {
  children: React.ReactNode
  currentVideoUid: string
  playNext: () => void
  autoPlay: boolean
}

export const VideoPlaylistContext = createContext<VideoPlaylistContextProps>({
  currentVideoUid: '',
  isVideoStarted: false,
  setIsVideoStarted: () => {},
  playNext: () => {},
})

export const VideoPlaylistProvider = ({
  children,
  autoPlay,
  ...props
}: VideoPlaylistProviderProps) => {
  const [isVideoStarted, setIsVideoStarted] = useState(autoPlay)

  return (
    <VideoPlaylistContext.Provider
      value={{ ...props, isVideoStarted, setIsVideoStarted }}
    >
      {children}
    </VideoPlaylistContext.Provider>
  )
}
