import { Theme } from '@scm/ui-core'

import { VideoPlayerIconProps } from '../../type'
import { VideoPlayerIconTheme1 } from './iconTheme1'
import { VideoPlayerIconTheme2 } from './iconTheme2'
import { selectors } from '@scm/redux-store'

export const VideoPlayerIcon = ({ className }: VideoPlayerIconProps) => {
  const { theme } = selectors.useConfig()

  return theme === Theme.THEME_1 ? (
    <VideoPlayerIconTheme1 className={className} />
  ) : (
    <VideoPlayerIconTheme2 className={className} />
  )
}
