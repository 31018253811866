import { useState } from 'react'

import { Media } from '@scm/ui-component-media'

import { videoPlayer } from '../../constants'
import { VideoPlayerWithThumbnailProps } from '../../type'
import { VideoPlayerThumbnail } from '../thumbnail'

export const VideoPlayerWithThumbnail = ({
  video,
  thumbnailImage,
}: VideoPlayerWithThumbnailProps) => {
  const [isVideoStarted, setIsVideoStarted] = useState(false)

  const startVideo = () => setIsVideoStarted(true)

  return (
    <div className={videoPlayer('Wrapper')} data-testid="player-with-thumbnail">
      <VideoPlayerThumbnail
        thumbnailImage={thumbnailImage}
        onClick={startVideo}
        className={videoPlayer('PlayerThumbnailWrapper', {
          isVideoStarted,
        })}
      />
      {isVideoStarted && (
        <div className={videoPlayer('MediaWrapper', { isVideoStarted })}>
          <Media {...video} isAutoplayEnabled={isVideoStarted} />
        </div>
      )}
    </div>
  )
}
