import { VideoPlayerIconProps } from '../../type'

export const VideoPlayerIconTheme2 = ({ className }: VideoPlayerIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="88"
    height="112"
    viewBox="0 0 88 112"
    fill="none"
    className={className}
  >
    <g filter="url(#filter0_d_1105_38653)">
      <path
        d="M62.8364 53.2028L35.9579 26.3435C34.5083 24.8953 32.5079 24 30.3011 24C26.8176 24 24 26.8127 24 30.2976V81.7073H24.0012C24.0012 85.1826 26.8223 88 30.3023 88H30.3527C32.5607 88 34.5599 87.1096 36.0095 85.6565L62.8364 58.8511C64.3915 57.3008 64.3915 54.7543 62.8364 53.2028Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1105_38653"
        x="0"
        y="0"
        width="88.0027"
        height="112"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="12" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1105_38653"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1105_38653"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
