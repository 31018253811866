import getTimezone from 'zipcode-to-timezone'

import { WeatherIconMap } from '.'

export const useWeatherIcon = (
  name: string,
  zipCode?: string,
  timeDay?: 'day' | 'night',
) => {
  let newTimeDay = timeDay || 'day'
  if (zipCode) {
    const { timeOfDay } = getTimeZoneByZipCode(zipCode)
    newTimeDay = timeOfDay
  }
  const map: Record<string, string> = WeatherIconMap[newTimeDay]

  for (const k in map) {
    if (
      // eslint-disable-next-line no-prototype-builtins
      map.hasOwnProperty(k) &&
      name?.match(new RegExp(k, 'im')) !== null
    ) {
      return { src: map[k] }
    }
  }
  return { src: map['clear'] }
}

export const getTimeZoneByZipCode = (
  zipCode: string,
): {
  timeOfDay: 'day' | 'night'
  isDay: boolean
  timeZoneLocation: string | null
  currentTimeLocation: string
} => {
  const tzLocation = getTimezone.lookup(zipCode || '50036')
  const currentTime = new Date().toLocaleString('en-US', {
    timeZone: tzLocation || 'America/New_York',
  })
  const hours = new Date(currentTime).getHours()
  const isDayTime = hours > 6 && hours < 20
  const timeOfDay = isDayTime ? 'day' : 'night'

  return {
    timeOfDay,
    isDay: isDayTime,
    timeZoneLocation: tzLocation,
    currentTimeLocation: currentTime,
  }
}
