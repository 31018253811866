import { VideoPlayerIconProps } from '../../type'

export const VideoPlayerIconTheme1 = ({ className }: VideoPlayerIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    className={className}
  >
    <path
      data-name="Icon awesome-play-circle"
      d="M7.563.563a7,7,0,1,0,7,7A7,7,0,0,0,7.563.563ZM10.828,8.24,5.86,11.091A.679.679,0,0,1,4.853,10.5V4.627A.679.679,0,0,1,5.86,4.034l4.968,3.02A.68.68,0,0,1,10.828,8.24Z"
      transform="translate(-0.563 -0.563)"
    />
  </svg>
)
