import { Image } from '@scm/ui-component-image'

import {
  THUMBNAIL_DEFAULT_QUALITY,
  THUMBNAIL_DEFAULT_WIDTH,
  videoPlayer,
} from '../constants'
import { PlayerThumbnailProps } from '../type'
import { VideoPlayerIcon } from './icon'

export const VideoPlayerThumbnail = ({
  thumbnailImage,
  onClick,
  className,
}: PlayerThumbnailProps) => {
  const { src, alt = '', width, quality } = thumbnailImage || {}

  return (
    <div
      onClick={onClick}
      role="presentation"
      className={videoPlayer('ThumbnailWrapper', [className])}
      data-testid="video-thumbnail"
    >
      <Image
        loading="eager"
        src={src}
        alt={alt}
        width={width || THUMBNAIL_DEFAULT_WIDTH}
        quality={quality || THUMBNAIL_DEFAULT_QUALITY}
        fallback="brand"
        format="jpg"
        draggable="false"
        className={videoPlayer('ThumbnailImage')}
      />

      <div className={videoPlayer('IconWrapper')} data-testid="video-play-icon">
        <VideoPlayerIcon className={videoPlayer('Icon')} />
      </div>
    </div>
  )
}
