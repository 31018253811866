import { VideoTemplateFragment } from '@scm/content-domain'
import {
  ALL_MEDIA_SOURCES,
  BrightcoveMediaProps,
  MediaContextProps,
  MediaSource,
  VIDEO_MEDIA_SOURCES,
  VideoMediaProps,
  VideoSource,
  getMediaSourceLink,
} from '@scm/ui-component-media'

export const getSourceLink = (
  source: MediaSource,
  id: string,
  accountId: string,
): string => {
  const isEmbed = source === 'embed'

  return !isEmbed ? getMediaSourceLink(source, id, accountId) : ''
}

export const isValidSource = (source: string): source is MediaSource => {
  return ALL_MEDIA_SOURCES.includes(source as MediaSource)
}

export const isNotValidVideoProp = (video: VideoTemplateFragment['video']) => {
  const { videoService, videoId, videoEmbedCode } = video || {}

  return !video || !videoService || (!videoId && !videoEmbedCode)
}

const isNotValidVideoSource = (videoSource: string | undefined) => {
  return !videoSource || !VIDEO_MEDIA_SOURCES.includes(videoSource)
}

export const getVideoProps = (
  video: VideoTemplateFragment['video'],
  mediaContext: MediaContextProps,
): BrightcoveMediaProps | VideoMediaProps | null => {
  const { videoService = '', videoId, videoEmbedCode } = video || {}
  const videoSource = videoService?.toLocaleLowerCase()

  if (isNotValidVideoProp(video) || isNotValidVideoSource(videoSource))
    return null

  switch (videoSource) {
    case 'embed':
      return getEmbededVideoProps(videoId, videoEmbedCode)
    case 'brightcove':
      return getBrightcoveVideoProps(videoId, mediaContext)
    default:
      return getCommonVideoProps(videoId, videoSource as VideoSource)
  }
}

export const getEmbededVideoProps = (
  videoId: string | undefined | null,
  videoEmbedCode: string | undefined | null,
): VideoMediaProps => {
  return {
    variant: 'video',
    id: videoId ?? '',
    source: 'embed',
    embedCode: videoEmbedCode ?? '',
  }
}

export const getBrightcoveVideoProps = (
  videoId: string | undefined | null,
  mediaContext: MediaContextProps,
): BrightcoveMediaProps | null => {
  const { brightcoveAccountId } = mediaContext

  if (!brightcoveAccountId) return null

  return {
    variant: 'video',
    id: videoId ?? '',
    source: 'brightcove',
    accountId: brightcoveAccountId,
  }
}

export const getCommonVideoProps = (
  videoId: string | undefined | null,
  videoSource: VideoSource,
): VideoMediaProps => {
  return {
    variant: 'video',
    id: videoId ?? '',
    source: videoSource as VideoSource,
  }
}
