import { ALL_MEDIA_SOURCES, MediaSource } from './type'

const sourceToLinkMapper: Record<
  Exclude<MediaSource, 'embed'>,
  (_id: string, _accountId: string) => string
> = {
  youtube: (id: string) => `https://www.youtube.com/embed/${id}`,
  vimeo: (id: string) => `https://player.vimeo.com/video/${id}`,
  brightcove: (id: string, accountId: string) =>
    `https://players.brightcove.net/${accountId}/default_default/index.html?videoId=${id}`,
  spotify: (id: string) =>
    `https://open.spotify.com/embed/track/${id}?utm_source=generator`,
  soundcloud: (id: string) =>
    `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${id}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`,
  captivate: (id: string) => `https://player.captivate.fm/episode/${id}`,
  blubrry: (id: string) =>
    `https://player.blubrry.com/id/${id}#textcolor-Dark&amp;backgroundcolor-648ab6`,
  on24: (id: string, accountId: string) =>
    `https://event.on24.com/wcc/r/${id}/${accountId}`,
  // embed: () => ''
}

export const getMediaSourceLinkWithAutoplay = (
  link: string,
  source: Exclude<MediaSource, 'embed'>,
): string => {
  switch (source) {
    case 'youtube':
    case 'vimeo':
      return `${link}?&autoplay=1&mute=0`
    case 'brightcove':
      return `${link}&autoplay=true&muted=true`
    default:
      return link
  }
}

type getMediaSourceLinkFunc = (
  source: Exclude<MediaSource, 'embed'>,
  id: string,
  accountId: string,
  isAutoplayEnabled?: boolean,
) => string

export const getMediaSourceLink: getMediaSourceLinkFunc = (
  source,
  id,
  accountId,
  isAutoplayEnabled = false,
) => {
  if (!Object.keys(sourceToLinkMapper).includes(source)) {
    throw new Error(`Service ${source} is not supported.`)
  }
  const baseLink = sourceToLinkMapper[source](id, accountId)
  return isAutoplayEnabled
    ? getMediaSourceLinkWithAutoplay(baseLink, source)
    : baseLink
}

export const isValidSource = (source: string): source is MediaSource => {
  return ALL_MEDIA_SOURCES.includes(source as MediaSource)
}

export const generatedSoundCloudLink = (link: string) =>
  `https://w.soundcloud.com/player/?url=${link}&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`
