import { weatherIcon } from './icons'

export type WeatherIconType =
  | 'Cloudy'
  | 'DayClear'
  | 'DayMostlyCloudy'
  | 'DayPartlyCloudy'
  | 'Ice'
  | 'NightClear'
  | 'NightMostlyCloudy'
  | 'NightPartlyCloudy'
  | 'Rain'
  | 'Snow'
  | 'ThunderStorms'
  | 'Windy'
  | 'Fog'

export interface WeatherIconProps {
  icon: WeatherIconType
}

export const WeatherIconMap = {
  day: {
    sun: weatherIcon.DayClear,
    clear: weatherIcon.DayClear,
    fair: weatherIcon.DayClear,
    mostly: weatherIcon.DayMostlyCloudy,
    cloud: weatherIcon.DayPartlyCloudy,
    wind: weatherIcon.Windy,
    fog: weatherIcon.Fog,
    hail: weatherIcon.Rain,
    haze: weatherIcon.Fog,
    lightning: weatherIcon.ThunderStorms,
    rain: weatherIcon.Rain,
    showe: weatherIcon.Rain,
    sleet: weatherIcon.Ice,
    snow: weatherIcon.Snow,
    overcast: weatherIcon.DayMostlyCloudy,
    storm: weatherIcon.ThunderStorms,
    thunder: weatherIcon.ThunderStorms,
    hot: weatherIcon.DayClear,
    cold: weatherIcon.Ice,
    blizz: weatherIcon.Snow,
    dust: weatherIcon.DayMostlyCloudy,
    smoke: weatherIcon.Fog,
    tornado: weatherIcon.ThunderStorms,
    mist: weatherIcon.Fog,
  },
  night: {
    sun: weatherIcon.NightClear,
    clear: weatherIcon.NightClear,
    fair: weatherIcon.NightClear,
    mostly: weatherIcon.NightMostlyCloudy,
    cloud: weatherIcon.NightPartlyCloudy,
    wind: weatherIcon.Windy,
    fog: weatherIcon.Fog,
    hail: weatherIcon.Rain,
    haze: weatherIcon.Fog,
    lightning: weatherIcon.ThunderStorms,
    rain: weatherIcon.Rain,
    showe: weatherIcon.Rain,
    sleet: weatherIcon.Ice,
    snow: weatherIcon.Snow,
    overcast: weatherIcon.NightMostlyCloudy,
    storm: weatherIcon.ThunderStorms,
    thunder: weatherIcon.ThunderStorms,
    hot: weatherIcon.NightClear,
    cold: weatherIcon.Ice,
    blizz: weatherIcon.Snow,
    dust: weatherIcon.NightMostlyCloudy,
    smoke: weatherIcon.Fog,
    tornado: weatherIcon.ThunderStorms,
    mist: weatherIcon.Fog,
  },
}
