import { ClassName } from '@scm/ui-tailwind'

export interface MediaContextProps {
  brightcoveAccountId: string
}

export type AudioSource =
  | 'spotify'
  | 'soundcloud'
  | 'captivate'
  | 'blubrry'
  | 'embed'

export type VideoSource = 'youtube' | 'vimeo' | 'embed'

export type MediaSource = AudioSource | VideoSource | 'brightcove' | 'on24'

export const AUDIO_MEDIA_SOURCES = [
  'spotify',
  'soundcloud',
  'captivate',
  'embed',
  'brightcove',
  'blubrry',
]

export const VIDEO_MEDIA_SOURCES = ['youtube', 'vimeo', 'brightcove', 'embed']

export const ALL_MEDIA_SOURCES = [
  ...AUDIO_MEDIA_SOURCES,
  ...VIDEO_MEDIA_SOURCES,
]

export type MediaVariant = 'audio' | 'video'

export interface BaseMediaProps {
  id: string
  source: MediaSource
  variant: MediaVariant
  className?: ClassName
  title?: string
  link?: string
  embedCode?: string
  isAutoplayEnabled?: boolean
}

export interface VideoMediaProps extends BaseMediaProps {
  variant: 'video'
  source: VideoSource
}

export interface AudioMediaProps extends BaseMediaProps {
  variant: 'audio'
  source: AudioSource
}

export interface BrightcoveMediaProps extends BaseMediaProps {
  variant: 'video'
  source: 'brightcove'
  accountId: string
}

export type MediaProps =
  | BrightcoveMediaProps
  | AudioMediaProps
  | VideoMediaProps
