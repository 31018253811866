// @ts-nocheck
import { WeatherIconProps } from '..'
import Cloudy from './cloudy.svg'
import DayClear from './day-clear.svg'
import DayMostlyCloudy from './day-mostly_cloudy.svg'
import DayPartlyCloudy from './day-partly_cloudy.svg'
import Fog from './fog.svg'
import Ice from './ice.svg'
import NightClear from './night-clear.svg'
import NightMostlyCloudy from './night-mostly_cloudy.svg'
import NightPartlyCloudy from './night-partly_cloudy.svg'
import Rain from './rain.svg'
import Snow from './snow.svg'
import ThunderStorms from './thunderstorms.svg'
import Windy from './windy.svg'

export const weatherIcon: Record<WeatherIconProps['icon'], string> = {
  Cloudy,
  DayClear,
  DayMostlyCloudy,
  DayPartlyCloudy,
  Ice,
  NightClear,
  NightMostlyCloudy,
  NightPartlyCloudy,
  Rain,
  Snow,
  ThunderStorms,
  Windy,
  Fog,
}
