import { useState } from 'react'
import { EventType, GTM } from '@scm/tag-manager'
import { Image } from '@scm/ui-component-image'
import { Link } from '@scm/ui-component-link'
import { Bem, getStyleBackgroundImage } from '@scm/ui-core'
import { EventFeatureProps } from './type'

const eventFeature = Bem('EventFeature')

const MODULE_UID = 'module_event_promo'

export const EventFeature = ({
  title,
  backgroundImage,
  backgroundColor,
  logoSrc,
  logoAlt,
  date,
  description,
  btnCTAText,
  btnCTALink = '',
  logoPosition,
  isConstrained,
  moduleLocation,
}: EventFeatureProps) => {
  const [isHovered, setIsHovered] = useState(false)
  const url = GTM.utils.getCurrentUrl()
  const sendEvent = GTM.hooks.useSendEvent()
  const styleImage = getStyleBackgroundImage(backgroundImage, {
    width: '800',
    format: 'png',
    auto: 'webp',
  })
  const linkStyle =
    !isConstrained && isHovered
      ? { color: backgroundImage ? '#000' : backgroundColor }
      : {}
  const trackGtmClick = (href: string) => {
    if (!url) return
    const payload = {
      name: title,
      location: moduleLocation,
      section: MODULE_UID,
      referring_page: url.pathname,
      destination_page: GTM.utils.prepareDestinationPath(href, url),
    }
    sendEvent({
      data: payload,
      event: EventType.NAVIGATION,
      _clear: true,
    })
  }
  const onLinkMouseOver = () => {
    setIsHovered(true)
  }

  const onLinkMouseOut = () => {
    setIsHovered(false)
  }

  const logoSection = (
    <div className={eventFeature('LogoSection', { isConstrained })}>
      <div className={eventFeature('AspectLogo')}>
        <Link
          to={btnCTALink}
          trackGtmClick={trackGtmClick}
          data-testid="event-feature-logo-link"
          aria-label={`Visit ${btnCTALink.split('.')[1]} link`}
        >
          <span className={eventFeature('ImageScreenReaderText')}>
            {logoAlt}
          </span>
          <Image
            src={logoSrc}
            alt=""
            className={eventFeature('Logo')}
            width={800}
            fallback={'brand'}
          />
        </Link>
      </div>

      {isConstrained && (
        <div
          className={eventFeature('Date', { displayOption: 'logo' })}
          data-testid="event-feature-logo-date"
        >
          {date}
        </div>
      )}
    </div>
  )

  const contentSection = (
    <div
      className={eventFeature('ContentSection', {
        isConstrained,
        logoPosition: logoPosition?.toLowerCase(),
      })}
    >
      {isConstrained && (
        <div
          className={eventFeature('Date', { displayOption: 'content' })}
          data-testid="event-feature-content-date"
        >
          {date}
        </div>
      )}
      <div
        className={eventFeature('Description', { isConstrained })}
        data-testid="event-feature-description"
      >
        {description}
      </div>
      {btnCTAText && (
        <Link
          to={btnCTALink}
          trackGtmClick={trackGtmClick}
          style={linkStyle}
          className={eventFeature('ButtonLink', { isConstrained })}
          target="_blank"
          rel="noreferrer"
          onMouseOver={onLinkMouseOver}
          onMouseOut={onLinkMouseOut}
          data-testid="event-feature-button"
        >
          {btnCTAText}
        </Link>
      )}
    </div>
  )

  return (
    <div
      className={eventFeature({ isConstrained })}
      data-module="event-feature"
    >
      <div
        style={styleImage.backgroundImage ? styleImage : { backgroundColor }}
        className={eventFeature('Wrapper', {
          hasBackgroundImage: !!backgroundImage,
          isConstrained,
        })}
        data-testid="event-feature-wrapper"
      >
        {logoPosition === 'Left' && logoSection}
        {contentSection}
        {logoPosition === 'Right' && logoSection}
      </div>
    </div>
  )
}
