import { useState } from 'react'
import { selectors } from '@scm/redux-store'
import { Ads } from '@scm/ads'
import { useGtmClick } from '@scm/tag-manager'
import { ChangeLocationBar } from '@scm/ui-component-change-location-bar'
import { Icon } from '@scm/ui-component-icon'
import { Link } from '@scm/ui-component-link'
import { Bem } from '@scm/ui-core'
import { useWeatherIcon } from '@scm/weather-icon'

import { WeatherWidgetProps, WindDirectionFullName } from './type'
import { toAcronymWindDirection } from './utils'

const weatherWidget = Bem('WeatherWidget')

const MODULE_UID = 'module_weather'

export const WeatherWidget = (props: WeatherWidgetProps) => {
  const {
    href,
    currentCondition = '',
    currentConditionIcon,
    currentTemperature,
    location,
    forecastHighTemperature,
    forecastLowTemperature,
    windSpeed,
    windDirection,
    moduleLocation,
    title,
    defaultValue,
  } = props

  const [resetValueToDefault, setResetValueToDefault] = useState<boolean>(false)
  const [showBoxChangeLocation, setShowBoxChangeLocation] =
    useState<boolean>(false)

  const { featureToggles } = selectors.useFlags()
  const { trackGtmClick } = useGtmClick(title, moduleLocation, MODULE_UID)

  const sponsorRender = Ads.hooks.useIsAdRender('sponsoredlogo_weather_1v')
  const currentWeatherIcon = useWeatherIcon(currentCondition, defaultValue)

  const isSponsorRender = sponsorRender === 'show'

  return (
    <div data-module="weather-widget" className={weatherWidget()}>
      <div
        className={weatherWidget('WeatherWrapper', {
          hidden: showBoxChangeLocation,
        })}
        data-testid="weather-widget-info-block"
      >
        <p className={weatherWidget('CurrentCondition')}>
          Current Conditions for
        </p>
        <h1
          className={weatherWidget('Location')}
          data-testid="weather-widget-location"
        >
          {location}
        </h1>
        <button
          className={weatherWidget('ChangeLocationButton')}
          onClick={() => {
            setShowBoxChangeLocation(!showBoxChangeLocation)
            setResetValueToDefault(false)
          }}
          data-testid="weather-widget-change-location-btn"
        >
          Change Location
        </button>
        <div className={weatherWidget('WeatherInfo')}>
          <div>
            <p
              className={weatherWidget('CurrentTemperature')}
              dangerouslySetInnerHTML={{ __html: currentTemperature }}
              data-testid="weather-widget-temperature"
            />
            <span
              className={weatherWidget('CurrentCondition')}
              data-testid="weather-widget-condition"
            >
              {currentCondition}
            </span>
          </div>
          <img
            className={weatherWidget('WeatherIcon')}
            src={currentWeatherIcon.src || currentConditionIcon}
            alt="weather-icon"
            data-testid="weather-widget-icon"
          />
          <div className={weatherWidget('WeatherInfoDetail')}>
            <p
              className={weatherWidget('DayTemperature')}
              data-testid="weather-widget-day-temp"
            >
              Day {forecastHighTemperature}º
            </p>
            <p
              className={weatherWidget('NightTemperature')}
              data-testid="weather-widget-night-temp"
            >
              Night {forecastLowTemperature}º
            </p>
            <div className={weatherWidget('WindSpeed')}>
              <Icon icon="Wind" className={weatherWidget('WindIcon')} />
              <span data-testid="weather-widget-wind">
                {windSpeed}{' '}
                {toAcronymWindDirection(windDirection as WindDirectionFullName)}
              </span>
            </div>
          </div>
        </div>
        <Link
          to={href || '/'}
          aria-label="detail-weather-report"
          data-testid="detail-weather-link"
          className={weatherWidget('DetailWeatherLink')}
          trackGtmClick={trackGtmClick}
        >
          See Detailed Weather Report
          <Icon
            icon="ChevronRight"
            className={weatherWidget('ChevronRightIcon')}
          />
        </Link>

        {featureToggles.weather3MonthForecastEnabled && (
          <Link
            to="/weather/3-month-forecast"
            aria-label="three-month-forecast-link"
            data-testid="three-month-forecast-link"
            className={weatherWidget('DetailWeatherLink')}
            trackGtmClick={trackGtmClick}
          >
            Weather 20/20: 3-month-out forecast
            <Icon
              icon="ChevronRight"
              className={weatherWidget('ChevronRightIcon')}
            />
          </Link>
        )}

        <div className={weatherWidget('SponsorWrapper', { isSponsorRender })}>
          {isSponsorRender && (
            <p className={weatherWidget('SponsorTitle')}>BROUGHT TO YOU BY</p>
          )}
          <Ads.Ad
            pos="sponsoredlogo_weather_1v"
            className={weatherWidget('SponsorAd')}
          />
        </div>
      </div>

      <div
        className={weatherWidget('ChangeLocation', {
          hidden: !showBoxChangeLocation,
        })}
        data-testid="weather-widget-change-location-block"
      >
        <div className={weatherWidget('CloseChangeLocation')}>
          <button
            aria-label="close-change-location"
            onClick={() => {
              setShowBoxChangeLocation(false)
              setResetValueToDefault(true)
            }}
            className={weatherWidget('CloseChangeLocationButton')}
          >
            <Icon
              icon="Close"
              className={weatherWidget('CloseChangeLocationIcon')}
            />
          </button>
        </div>
        <div className={weatherWidget('ChangeLocationWrapper')}>
          <ChangeLocationBar
            {...props}
            handleChangeLocation={zipCode => {
              props.handleChangeLocation(zipCode)
              setShowBoxChangeLocation(false)
            }}
            variant="weather"
            placeholderText="Change location"
            submitText="CHANGE LOCATION"
            resetValueToDefault={resetValueToDefault}
          />
          <p className={weatherWidget('ChangeLocationTextNote')}>
            Enter a zip code to see the weather conditions for a different
            location.
          </p>
        </div>
      </div>
    </div>
  )
}
