import { Bem } from '@scm/ui-core'

import { MediaProps } from './type'
import {
  generatedSoundCloudLink,
  getMediaSourceLink,
  isValidSource,
} from './utils'

const media = Bem('Media')

/**
 * Add loading="lazy" attribute to all the iframe nodes for the input
 * html string, only if loading attribute doesn't exists for the iframe node.
 * return actual html string in case of html parsing error.
 * @param html Embed code html string
 */
const addLazyLoadingAttr = (html: string) => {
  try {
    const parser = new DOMParser()
    const htmlDoc = parser.parseFromString(html, 'text/html')
    const iframeNodes = htmlDoc.querySelectorAll('iframe')
    for (const iframeNode of iframeNodes) {
      if (!iframeNode?.attributes.getNamedItem('loading')) {
        iframeNode?.setAttribute('loading', 'lazy')
      }
    }
    return htmlDoc?.body?.innerHTML
  } catch {
    return html
  }
}
export const Media = (props: MediaProps) => {
  const {
    variant,
    id,
    source,
    className,
    title,
    link = '',
    embedCode,
    isAutoplayEnabled = false,
  } = props

  if (!isValidSource(source)) return null

  const isEmbed = source === 'embed'
  const src = !isEmbed
    ? getMediaSourceLink(
        source,
        id,
        'accountId' in props ? props['accountId'] : '',
        isAutoplayEnabled,
      )
    : ''

  return (
    <div data-component="media">
      {isEmbed && embedCode ? (
        <div
          className={media({ [variant]: true }, [className])}
          dangerouslySetInnerHTML={{ __html: addLazyLoadingAttr(embedCode) }}
        />
      ) : (
        <div className={media({ [variant]: true }, [className])}>
          <iframe
            data-ot-ignore
            loading="lazy"
            className={media('Iframe')}
            data-testid="media-iframe"
            src={
              id
                ? src
                : source === 'soundcloud'
                  ? generatedSoundCloudLink(link)
                  : link
            }
            frameBorder="0"
            allow="autoplay;accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; "
            allowFullScreen
            aria-label="Play Audio Media"
            title={title ?? ''}
          />
        </div>
      )}
    </div>
  )
}
