import ReactPlayer from 'react-player'

import { isValidSource } from '@scm/ui-component-media'

import {
  DEFAULT_PLAYER_HEIGHT,
  DEFAULT_PLAYER_WIDTH,
  videoPlayer,
} from '../../constants'
import { SourceBasedPlayerProps } from '../../type'
import { getSourceLink } from '../../utils'
import { BrightcovePlayer } from './brightcovePlayer'

export const SourceBasedPlayer = ({
  video,
  width,
  height,
  setIsVideoStarted,
  playNext,
}: SourceBasedPlayerProps) => {
  const { source, id } = video
  const isEmbed = source === 'embed'
  const accountId = 'accountId' in video ? video['accountId'] : ''

  if ((isEmbed || !isValidSource(source)) && playNext) {
    playNext()
  }

  const src = getSourceLink(source, id, accountId)

  const onPlay = () => {
    if (setIsVideoStarted) {
      setIsVideoStarted(true)
    }
  }

  return (
    <div
      className={videoPlayer('PlayerAcpect')}
      data-testid="source-based-player"
    >
      {(source === 'youtube' || source === 'vimeo') && (
        <ReactPlayer
          url={src}
          controls={true}
          playing
          onEnded={playNext}
          onPlay={onPlay}
          onError={playNext}
          width={width || DEFAULT_PLAYER_WIDTH}
          height={height || DEFAULT_PLAYER_HEIGHT}
        />
      )}
      {source === 'brightcove' && (
        <BrightcovePlayer
          key={id}
          videoId={id}
          accountId={accountId}
          onEnded={playNext}
          onPlay={onPlay}
          onError={playNext}
          width={width}
          height={height}
        />
      )}
    </div>
  )
}
