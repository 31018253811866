/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useRef } from 'react'

import { DEFAULT_PLAYER_HEIGHT, DEFAULT_PLAYER_WIDTH } from '../../constants'
import { BrightcovePlayerProps } from '../../type'

export const BrightcovePlayer = ({
  videoId,
  accountId,
  onPlay,
  onEnded = () => null,
  onError,
  width,
  height,
}: BrightcovePlayerProps) => {
  const videoRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const script = document.createElement('script')
    script.src = `//players.brightcove.net/${accountId}/default_default/index.min.js`
    script.type = 'text/javascript'
    const player = videoRef.current

    player?.appendChild(script)

    return () => {
      player?.removeChild(script)
    }
  }, [accountId])

  return (
    <div ref={videoRef}>
      <video
        data-video-id={videoId}
        data-account={accountId}
        data-player="default"
        data-embed="default"
        data-application-id
        onPlay={onPlay}
        onEnded={() => onEnded()}
        onError={onError}
        autoPlay
        controls
        width={width || DEFAULT_PLAYER_WIDTH}
        height={height || DEFAULT_PLAYER_HEIGHT}
      />
    </div>
  )
}
