/* eslint-disable complexity */

/* eslint-disable jsx-a11y/anchor-is-valid */
import { EventType, GTM } from '@scm/tag-manager'
import { Button } from '@scm/ui-component-button'
import { Icon } from '@scm/ui-component-icon'
import { Link } from '@scm/ui-component-link'
import { Bem, convertDateToShortDateString, renderMarkdown } from '@scm/ui-core'
import { CardTime } from '@scm/ui-module-card-time'

import { ContentListProps } from './type'

const contentList = Bem('ContentList')

export const ContentList = ({
  articles,
  listTitle,
  icon,
  ctaButton,
  openInNewTab,
  moduleLocation,
  moduleUid,
  isShowDate = true,
}: ContentListProps) => {
  if (!articles?.length) return null

  const url = GTM.utils.getCurrentUrl()
  const sendEvent = GTM.hooks.useSendEvent()

  const trackGtmClick = (href: string) => {
    if (!url || !moduleUid) return
    const payload = {
      name: listTitle,
      location: moduleLocation,
      section: moduleUid,
      referring_page: url.pathname,
      destination_page: GTM.utils.prepareDestinationPath(href, url),
    }
    sendEvent({
      data: payload,
      event: EventType.NAVIGATION,
      _clear: true,
    })
  }

  return (
    <div data-component="content-list" className={contentList()}>
      <div className={contentList('Header')}>
        {icon && <Icon icon={icon} className={contentList('Icon')} />}
        <span
          className={contentList('Title')}
          data-testid="content-list-title"
          dangerouslySetInnerHTML={{ __html: renderMarkdown(listTitle) }}
        />
      </div>
      <ul className={contentList('List')}>
        {articles.map((article, index) => {
          const isWebinar =
            listTitle.toLowerCase().includes('webinar') ||
            article.contentType === 'Webinar'

          const date = isWebinar
            ? (article.eventDate ?? article.publishedDate)
            : article.publishedDate

          return (
            <li key={article.url + index} className={contentList('ListItem')}>
              <Link
                to={article.url}
                trackGtmClick={trackGtmClick}
                target={openInNewTab || article.isExternalLink ? '_blank' : ''}
                rel={openInNewTab || article.isExternalLink ? 'nofollow' : ''}
                data-testid="content-list-item-title"
              >
                <p
                  className={contentList('ListItemTitle')}
                  dangerouslySetInnerHTML={{
                    __html: renderMarkdown(article.title),
                  }}
                />
              </Link>
              {isShowDate && (article.publishedDate || article.eventDate) && (
                <span
                  className={contentList('ListItemDate', {
                    webinar: isWebinar,
                  })}
                  data-testid="content-list-item-date"
                >
                  {convertDateToShortDateString(date)}
                  <CardTime
                    timeRead={article.readTime}
                    date={date}
                    webinarStartTime={article.webinarStartTime}
                    contentType={article.contentType ?? 'Article'}
                  />
                </span>
              )}
            </li>
          )
        })}
      </ul>
      {ctaButton?.ctaButtonText?.trim() && ctaButton?.ctaButtonLink?.trim() ? (
        <div className={contentList('CTAButton')}>
          <Button
            size="medium"
            variant="text-link"
            target="_blank"
            rightIcon="ChevronRight"
          >
            <Link
              className={contentList('CTAText')}
              to={ctaButton.ctaButtonLink}
              onClick={() => trackGtmClick(ctaButton?.ctaButtonLink ?? '')}
              target="_blank"
              rel="noreferrer"
            >
              {ctaButton.ctaButtonText}
            </Link>
          </Button>
        </div>
      ) : null}
    </div>
  )
}
