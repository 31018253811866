import { WindDirectionFullName } from './type'

export const toAcronymWindDirection = (
  windDirection: WindDirectionFullName,
) => {
  switch (windDirection) {
    case 'North':
      return 'N'
    case 'Northeast':
      return 'NE'
    case 'East':
      return 'E'
    case 'Southeast':
      return 'SE'
    case 'South':
      return 'S'
    case 'Southwest':
      return 'SW'
    case 'West':
      return 'W'
    case 'Northwest':
      return 'NW'
    default:
      return convertCustomNameDirection(windDirection)
  }
}

const convertCustomNameDirection = (windDirection: string | undefined) => {
  if (!windDirection) return ''
  if (windDirection.includes('Northeast')) return 'NNE'
  if (windDirection.includes('Southeast')) return 'SSE'
  if (windDirection.includes('Southwest')) return 'SSW'
  if (windDirection.includes('Northwest')) return 'NNW'
}
